
import { createStore } from 'vuex';

const modules = requireAllStoreModules();

function requireAllStoreModules () {
    // `import.meta.webpackContext` allows us to bundle all files in a directory that match a regex.
    // https://webpack.js.org/api/module-variables/#importmetawebpackcontext
    const r = import.meta.webpackContext('./modules', { recursive: false, regExp: /\.js$/ });
    const moduleObjects = r.keys().map(id => ({
        [moduleIdToName(id)]: r(id).default,
    }));
    return Object.assign({}, ...moduleObjects);
}

function moduleIdToName (id) {
    return id.replace(/^\.\//, '').replace(/\.js$/, '');
}

export default createStore({
    modules,

    strict: process.env.NODE_ENV !== 'production',
});
