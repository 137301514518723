import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer-links" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "artificial-tests"
}
const _hoisted_10 = ["textContent"]
const _hoisted_11 = ["src"]

import { computed } from 'vue';
    import { useI18next } from '@composables/i18next';
    import { useStore } from 'vuex';
    import { OutgoingAffiliate } from '@roc/affiliate';
    import { openPopup } from '../popup';

    
export default {
  __name: 'TheFooter',
  setup(__props) {

    const store = useStore();

    const { t, vT } = useI18next(['footer', 'links', 'global']);

    const profile = computed(() => store.state.profile);
    const app = computed(() => store.state.app);
    const ui = computed(() => store.state.ui);
    const loggedIn = computed(() => store.getters['profile/loggedIn']);

    const logOut = () => store.dispatch('profile/logOut');

return (_ctx, _cache) => {
  const _directive_track = _resolveDirective("track")

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _unref(t)('links:rules_url', { tb2Url: app.value.tb2URL }),
            target: "_blank"
          }, null, 8, _hoisted_3), [
            [_unref(vT), 'links:rules_copy_us'],
            [_directive_track, ['click', { category: 'Footer', label: 'Official Rules US' }]]
          ])
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("li", { class: "divider" }, null, -1)),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _unref(t)('links:privacy_url'),
            target: "_blank"
          }, null, 8, _hoisted_4), [
            [_unref(vT), 'links:privacy_copy'],
            [_directive_track, ['click', { category: 'Footer', label: 'Privacy Policy US' }]]
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("li", { class: "divider large-only" }, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("li", { class: "break small-only" }, null, -1)),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _unref(t)('links:rules_url_ca', { tb2Url: app.value.tb2URL }),
            target: "_blank"
          }, null, 8, _hoisted_5), [
            [_unref(vT), 'links:rules_copy_ca'],
            [_directive_track, ['click', { category: 'Footer', label: 'Official Rules CA' }]]
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("li", { class: "divider" }, null, -1)),
        _createElementVNode("li", null, [
          _withDirectives(_createElementVNode("a", {
            href: _unref(t)('links:privacy_url_ca'),
            target: "_blank"
          }, null, 8, _hoisted_6), [
            [_unref(vT), 'links:privacy_copy_ca'],
            [_directive_track, ['click', { category: 'Footer', label: 'Privacy Policy CA' }]]
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("li", { class: "divider large-only" }, null, -1)),
        _cache[6] || (_cache[6] = _createElementVNode("li", { class: "break small-only" }, null, -1)),
        _createElementVNode("li", null, [
          _withDirectives((_openBlock(), _createElementBlock("a", {
            href: "/faq",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_unref(openPopup) && _unref(openPopup)(...args)), ["prevent"]))
          }, [
            _createTextVNode(_toDisplayString(_ctx.$t('links:faq_copy')), 1)
          ])), [
            [_directive_track, ['click', { category: 'Footer', label: 'FAQ' }]]
          ])
        ])
      ])
    ]),
    _createElementVNode("p", {
      class: "disclaimer",
      innerHTML: _unref(t)('footer:disclaimer', { tb2Url: app.value.tb2URL })
    }, null, 8, _hoisted_7),
    _createElementVNode("p", {
      class: "disclaimer",
      innerHTML: _unref(t)('footer:recaptchaDisclaimer')
    }, null, 8, _hoisted_8),
    (app.value.isAutomatedTest)
      ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
          _createElementVNode("span", {
            class: "test-copy",
            textContent: _toDisplayString(_unref(t)('testNamespace:testKey'))
          }, null, 8, _hoisted_10),
          _createElementVNode("img", {
            class: "language-flag",
            alt: "Language flag",
            src: _ctx.$i18nAsset('img/flag.svg'),
            style: {"display":"inline","width":"2em","padding":"0 0.5em","vertical-align":"middle"}
          }, null, 8, _hoisted_11)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}