import { unref as _unref, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-error" }

import { useI18next } from '@composables/i18next';
    import { usePage } from '@composables/page';

    
export default {
  __name: 'ErrorPage',
  setup(__props) {

    const { vT } = useI18next('error');
    usePage('error');

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _withDirectives(_createElementVNode("h2", null, null, 512), [
      [_unref(vT), 'error:headline_copy']
    ]),
    _withDirectives(_createElementVNode("p", null, null, 512), [
      [_unref(vT), 'error:body_copy']
    ])
  ]))
}
}

}