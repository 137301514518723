import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@public/img/starbucks-logo.png'


const _hoisted_1 = {
  id: "header",
  role: "banner",
  "data-e2e": "global-header"
}
const _hoisted_2 = { class: "visually-hidden" }

import { ref, onMounted } from 'vue';
    import { useI18next } from '@composables/i18next';
    
export default {
  __name: 'TheHeader',
  setup(__props) {

    const { t } = useI18next(['global']);

    // data
    const h1 = ref(null);

    // Focus the h1 element to force screen readers back to the top after a navigation.
    // This will ensure screen readers will read the page again.
    // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
    onMounted(() => h1.value.focus());

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h1", {
      ref_key: "h1",
      ref: h1,
      tabindex: "-1"
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Starbucks"
      }, null, -1)),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('global:title')), 1)
    ], 512)
  ]))
}
}

}