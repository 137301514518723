import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '@public/img/hero.jpg'


const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  id: "main-section",
  role: "main"
}
const _hoisted_3 = {
  key: 0,
  id: "secondary-section"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHeader = _resolveComponent("TheHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_TheFooter = _resolveComponent("TheFooter")
  const _component_CookieGate = _resolveComponent("CookieGate")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$store.state.ui.rapidTemplate ? [
                'rapid-template',
                `rapid-template-${_ctx.$store.state.ui.rapidTemplate}`,
            ] : [])
    }, [
      _createVNode(_component_TheHeader),
      _createElementVNode("main", _hoisted_2, [
        _createVNode(_component_router_view, { class: "content" })
      ]),
      (!!_ctx.$store.state.ui.rapidTemplate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              class: "hero-img"
            }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.ui.pageName != 'default')
      ? (_openBlock(), _createBlock(_component_TheFooter, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_CookieGate, {
      "body-copy": $setup.t('cookie_gate:body_copy'),
      "button-copy": $setup.t('cookie_gate:button_copy'),
      "title-copy": $setup.t('cookie_gate:title')
    }, null, 8, ["body-copy", "button-copy", "title-copy"])
  ]))
}