import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal",
  role: "dialog"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { tabindex: "-1" }, " Your Session Has Expired ", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, "Please log in again.", -1)),
      _createElementVNode("a", {
        href: "#",
        "data-e2e": "session-expiration-modal-close",
        onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, "Continue")
    ])
  ]))
}